import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box my={3} mdxType="Box"><Card variant="horizontal-content" image="restauracia/65631585_1105874569610423_5149917318368722944_o.jpg" imagePosition="90% 50%" title="Odštartujte leto s nami" mdxType="Card">
        <p>{`OTVARÁME od 1. júna a počas celého mesiaca JÚN sme si pre Vás pripravili pár zaujímavých prekvapení:`}</p>
        <ul>
          <li parentName="ul">{`1 dieťa do 12 rokov má u nás pobyt úplne zadarmo`}</li>
          <li parentName="ul">{`obedový balíček na cestu každý deň, aby ste na svojom výlete neostali hladní od nás ZADARMO`}</li>
          <li parentName="ul">{`u nás si viete uplatniť rekreačný poukaz.`}</li>
        </ul>
      </Card></Box>
    <Box my={3} mdxType="Box"><Card variant="horizontal-content" image="aktuality/bicykle.jpg" imagePosition="50% 70%" title="Raj na biku" mdxType="Card">
        <p>{`Spoznajte Slovenský raj na bicykli. E-bike si môžete požičať priamo u nás.`}</p>
        <p>{`Požičanie bicykla podlieha dostupnosti.`}<br />{`
V prípade záujmu nás prosím kontaktujte min. 24 hod. vopred.`}</p>
        <p><strong parentName="p">{`Cena požičania e-biku: 25 € / deň`}</strong></p>
      </Card></Box>
    <Box my={3} mdxType="Box"><Card variant="horizontal-content" image="aktuality/covid19.jpg" imagePosition="50% 30%" title="COVID 19 Opatrenia" mdxType="Card">
        <p>{`Prísne dodržiavanie hygienických predpisov je u nás samozrejmosťou a všetci zamestnanci boli preškolení aj ohľadom aktuálnych opatrení.`}</p>
        <p>{`Robíme maximum pre to, aby vaša dovolenka u nás bola bezpečná a zároveň zážitkom.`}</p>
        <ul>
          <li parentName="ul">{`úchyty na dverách do WC a iných spoločenských priestoroch sú každú hodinu čistené dezinfekciou`}</li>
          <li parentName="ul">{`pri vstupoch do hotela a v priestoroch hotela budú k dispozícii dezinfekčné prostriedky`}</li>
          <li parentName="ul">{`všetci zamestnanci pracujú s jednorazovými rukavicami a rúškami`}</li>
          <li parentName="ul">{`dodržiavame minimálne odstupy pri check-in, check-out a pri konzumácii jedál na terasách`}</li>
        </ul>
        <p>{`Taktiež očakávame od našich hostí, že budú dodržiavať hygienické predpisy a riadiť sa mimoriadnymi opatreniami.
V prípade podozrenia na vírusové ochorenie je potrebné kontaktovať svojho obvodného lekára.`}</p>
      </Card></Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      